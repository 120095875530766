import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Enable production mode if the environment is set to production
if (environment.production) {
  // Check if the window object exists to prevent issues in server-side rendering
  if (typeof window !== 'undefined' && window) {
    window.console.log = function() {};
  }

  if (window) {
      window.console.log =
        window.console.warn =
        window.console.info =
        window.console.debug =
        window.console.dir =
        window.console.assert =
        window.console.count =
        window.console.error =
        window.console.group =
        window.console.groupEnd =
        window.console.table =
        window.console.time =
        window.console.timeEnd =
        window.console.timeStamp =
        () => { };
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
