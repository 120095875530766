import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationService } from 'src/app/services/information.service';
import { SearchRequest } from '@interfaces/shared-components.interface';
import { LocalStorageService, SweetAlertService } from '@services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-agreement-template',
  templateUrl: './agreement-template.component.html',
  styleUrls: ['./agreement-template.component.scss'],
})
export class AgreementTemplateComponent implements OnInit {
  stylesAgreement: any;

  searchResults: any;
  term: string = '';
  municipalityId: string = '';
  isLoaded: boolean = false;
  videoVisible: boolean = false;
  buttonVisible: boolean = true;

  // Contador de usuarios
  defaultValue: number = 138600;
  usersValueAfter!: number;
  usersValue!: number;
  intervalId: any;

  //Specialities list
  public searchServiceForm!: FormGroup;
  public municipalities: any[] = [];

  //Videos
  videoUrl1: any;
  videoUrl2: any;

  //Lottie options
  options: AnimationOptions = {
    path: 'assets/lottie-animations/doctor.json',
  };

  //hash convenio
  hash: any;

  dataForSections: any;

  // Array de FAQ
  items: any[] = [
    {
      title: '¿Cómo agendo una cita médica y qué cobertura tienen?',
      description:
        'Ingresa en el buscador la especialidad, el nombre del médico o palabra clave del servicio que necesitas, y en el buscador de ubicación ingresa el municipio donde requieres ser atendido; recuerda que tenemos presencia en Pasto, Túquerres, Ipiales, Tumaco, Mocoa, Popayán, Cali y Armenia.',
      isOpen: false,
    },
    {
      title: '¿Qué médicos puedo encontrar con ustedes? ',
      description:
        'Ofrecemos una amplia gama de servicios de salud, que incluyen consultas con médicos generales y especialistas, exámenes de laboratorio, radiología, procedimientos quirúrgicos y no quirúrgicos y atención médica especializada. Actualmente, contamos con más de 1,650 servicios disponibles a través de nuestra plataforma, lo que te permite acceder a la atención que necesitas para ti y tu familia de manera rápida y eficiente.',
      isOpen: false,
    },
    {
      title: '¿Qué debo hacer si tengo problemas para agendar una cita?',
      description:
        'Si necesitas ayuda para agendar una cita, puedes comunicarte con nuestro call center, disponible de lunes a viernes en horario de oficina. Estaremos encantados de resolver tus dudas y asistirte en el proceso; simplemente haz clic en el logo de WhatsApp en nuestra página o escríbenos al 3174785093 para obtener asistencia de manera rápida y sencilla.',
      isOpen: false,
    },
    {
      title: '¿Los descuentos se aplican a todas las citas médicas?',
      description:
        'Sí, los descuentos se aplican a todas las citas que agendes a través de nuestra plataforma para los diferentes servicios de salud con los que tenemos convenio. Recuerda que los descuentos se aplican automáticamente al momento de agendar tu cita, exclusivamente en nuestra plataforma o en la App DESCUENTOS MÉDICOS.',
      isOpen: false,
    },
  ];

  //Doctors
  doctors = [
    {
      img: '../../assets/images/convenios/imgs/1.png',
    },
    {
      img: '../../assets/images/convenios/imgs/2.png',
    },
    {
      img: '../../assets/images/convenios/imgs/3.png',
    },
    {
      img: '../../assets/images/convenios/imgs/4.png',
    },
    {
      img: '../../assets/images/convenios/imgs/5.png',
    },
    {
      img: '../../assets/images/convenios/imgs/6.png',
    },
    {
      img: '../../assets/images/convenios/imgs/7.png',
    },
    {
      img: '../../assets/images/convenios/imgs/8.png',
    },
    {
      img: '../../assets/images/convenios/imgs/9.png',
    },
    {
      img: '../../assets/images/convenios/imgs/10.png',
    },
  ];

  testimonials = [
    {
      img: 'https://kit.glmedialibrary.com/8707/hombre2.png?tr=w-100?tr=w-300',
      name: 'Andrés Llorente',
      testimony: '“Es una excelente aplicación para toda la familia y en especial para nuestros hijos peluditos. Obtuvimos descuentos en nuestras consultas y pudimos gestionar las citas de manera fácil y rápida”',
      occupation: 'Contador '
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/mujer2.png?tr=w-100?tr=w-300',
      name: 'Ana Santander',
      testimony: '“Estoy muy satisfecho con el servicio de DESCUENTOS MÉDICOS App. Gracias a ellos, pude encontrar un especialista que me brindó la atención que necesitaba. Lo recomiendo”',
      occupation: 'Ingeniera'
    },
    {
      img: 'https://kit.glmedialibrary.com/8707/mujer1.png?tr=w-100?tr=w-300',
      name: 'Sara Solarte',
      testimony: '“¡Esta aplicación de DESCUENTOS MÉDICOS es realmente útil! Me ha ahorrado dinero en mis visitas al médico”',
      occupation: 'Docente'
    }
  ]

  companies = [
    { name: 'BigRock', logo: 'assets/img/companies/bigrock.png' },
    { name: 'Boat', logo: 'assets/img/companies/boat.png' },
    { name: 'Cars24', logo: 'assets/img/companies/car24.png' },
    { name: 'Delhivery', logo: 'assets/img/companies/delivery.png' },
    { name: 'DigitalV', logo: 'assets/img/companies/digitalv.png' },
    { name: 'Groww', logo: 'assets/img/companies/groww.png' },
    { name: 'HiTech', logo: 'assets/img/companies/hitech.png' },
    { name: 'Lenskart', logo: 'assets/img/companies/lenshkart.png' },
    { name: 'Meesho', logo: 'assets/img/companies/meesho.png' },
    { name: 'Paytm', logo: 'assets/img/companies/paytm.png' },
    { name: 'Physics Wallah', logo: 'assets/img/companies/pw.png' },
    { name: 'Swiggy', logo: 'assets/img/companies/swiggy.png' }
  ];
  selectedData: any;


  constructor(
    private infoService: InformationService,
    private router: Router,
    private swalService: SweetAlertService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    this.inicializarCompanyForm();

    this.searchServiceForm = new FormGroup({
      municipality: new FormControl(''),
      searchData: new FormControl(''),
    });
  }

  ngOnInit(): void {
    // Inicializa variables
    this.videoVisible = false;
    this.buttonVisible = true;
    // Carga datos desde el JSON y realiza las acciones necesarias
    this.loadDataFromJson();

    // Maneja el hash en la ruta
    this.route.params.subscribe((params) => {
      const hash = params['hash'];
      if (hash) {
        // console.log('hash', hash);
        // almacenar el convenio en el localStorage para redireccionar aca despues de solicitar cita con convenio
        this.localStorage.setItem('convenio', hash);
        this.loadData(hash);
      } else {
        console.warn('No se proporcionó un hash en los parámetros.');
        this.router.navigate(['/']);
      }
    });

    // Llama a iniciarData
    this.iniciarData();
  }

  private loadDataFromJson(): void {
    this.http.get('assets/json/agreement.json').subscribe({
      next: (data: any) => {
        this.dataForSections = data;
        console.log('Datos cargados correctamente:', this.dataForSections);
      },
      error: (err) => {
        console.error('Error al cargar los datos del JSON:', err);
      }
    });
  }

  private loadData(hash: string): void {
    if (!this.dataForSections) {
      console.warn('Los datos aún no están cargados. Esperando...');
      setTimeout(() => this.loadData(hash), 100); // Reintento con espera
      return;
    }

    // Filtra el objeto por el id igual al hash
    const selectedData = this.dataForSections.find((item: { id: string }) => item.id === hash);

    if (!selectedData) {
      console.error('No se encontraron datos para el hash:', hash);
      this.router.navigate(['/']);
      return;
    }

    this.selectedData = selectedData;
    this.stylesAgreement = this.selectedData.styles;

    // Busca la imagen activa en principal.img
    const principalImg = this.selectedData.principal?.img?.find((img: { active: boolean }) => img.active);

    if (principalImg?.path) {

      this.options = { path: principalImg.path };

    } else {
      console.warn('No se encontró una imagen activa en principal.img');
    }

    if (!this.options) {
      console.warn('No se encontró una imagen activa en principal.img');
    }

    // Carga los videos si existen
    const videos = this.selectedData.secondary?.[1]?.video;
    if (videos?.length >= 2) {
      this.videoUrl1 = this.sanitizer.bypassSecurityTrustResourceUrl(videos[0].path);
      this.videoUrl2 = this.sanitizer.bypassSecurityTrustResourceUrl(videos[1].path);
    } else {
      console.warn('No se encontraron videos secundarios adecuados.');
    }
  }




  getSafeUrl(videoUrl: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(videoUrl);
  }

  volverArriba() {
    let posicion = window.pageYOffset;
    const intervalId = setInterval(() => {
      window.scrollTo({ top: posicion, behavior: 'auto' });
      if (posicion > 0) {
        posicion -= 30; // ajusta este número para cambiar la velocidad de la animación
      } else {
        clearInterval(intervalId);
      }
    }, 10); // este número está relacionado con la frecuencia de actualización de los navegadores (60Hz = 16.67ms por fotograma)
  }

  abrirWhatsApp() {
    const url =
      'https://api.whatsapp.com/send?phone=573174785093&text=Hola!%20me%20interesa%20saber%20de%20la%20aplicación';
    window.open(url, '_blank');
  }


  iniciarData() {
    this.infoService
      .getMunicipalities()
      .then((resp: any) => {
        this.municipalities = resp;
        ////console.log(this.municipalities);
      })
      .catch(() => {
        this.municipalities = [];
      });
  }

  inicializarCompanyForm() {
    this.searchServiceForm = new FormGroup({
      municipality: new FormControl('', Validators.required),
      searchData: new FormControl('', Validators.required),
    });
  }

  searchSubservices() {
    let municipalityControl = this.searchServiceForm.get('municipality');
    let municipalityValue = municipalityControl
      ? municipalityControl.value
      : null;

    let searchDataControl = this.searchServiceForm.get('searchData');
    let searchDataValue = searchDataControl ? searchDataControl.value : null;

    if (municipalityValue && searchDataValue) {
      const searchRequest: SearchRequest = {
        municipalityId: municipalityValue._id,
        term: searchDataValue,
        filters: {
          price: {
            enabled: false,
            min: 0,
            max: 0,
          },
          provider: true,
          doctor: true,
          services: {
            pets: true,
            humans: true,
          },
        },
      };

      this.infoService.search(searchRequest).subscribe(
        (searchResults: any) => {
          if (searchResults.body || searchResults.ok == true) {
            let mun = municipalityValue._id;
            let search = searchDataValue;
            let city = municipalityValue.municipality;
            this.isLoaded = true;

            this.router.navigate(['/directory'], {
              queryParams: {
                mun,
                city: JSON.stringify(city),
                search,
              },
            });
          } else {
            this.isLoaded = false;
          }
        },
        (searchError: any) => {
          console.error('Error en la búsqueda:', searchError);
        }
      );
    } else {
      this.swalService.lauchSwal(
        '',
        'Ingresa tu ubicación y busqueda <br> (especialidad, médico o afección)',
        'warning'
      );
    }
  }

  toggleItem(index: number): void {
    this.items[index].isOpen = !this.items[index].isOpen;

    this.items.forEach((item, i) => {
      if (i !== index) item.isOpen = false;
    });
  }
}
