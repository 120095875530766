import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { login, loginSuccess, loginFailure, logout } from './auth.actions';
import { AuthService } from '../services/auth.service';
import { ApiResponse } from 'src/app/interfaces/response.interface';
import { Router } from '@angular/router';
import { GoogleService } from '../services/google.service';
import { ManageUserCompanyStatesService } from 'src/app/services/manage-user-company-states.service';
import { SweetAlertService } from '@services';

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private googleService: GoogleService,
    private swalService: SweetAlertService,
    private manageUserCompanyStates: ManageUserCompanyStatesService // Llama el servicio para completar el perfil cuando un usuario  lo tiene incompleto
  ) {}


  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      mergeMap(({ user }) => {
        // console.log('Acción login recibida', user);
        return this.authService.login(user).pipe(
          map((res: ApiResponse) => {
            // console.log('Login exitoso', res);
            return loginSuccess({ body: res.body });
          }),
          catchError((error) => {
            this.swalService.showError('','Usuario o contraseña incorrecta');
            // console.log('Error en login', error);
            return of(loginFailure({ error: error.message }));
          })
        );
      })
    )
  );


  /*
  login$ = createEffect(() =>
  this.actions$.pipe(
    ofType(login),
    mergeMap(({ user }) =>
      this.authService.login(user).pipe(
        map((res: ApiResponse) => loginSuccess({ body: res.body })),
        catchError((error) => of(loginFailure({ error: error.message })))
      )
    )
  ));
  */


  // loginSuccess$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(loginSuccess, regenerateAuthState),
  //     tap(() => {
  //       this.manageUserCompanyStates.checkUserStates();
  //     })
  //   ),
  //   { dispatch: false } // No se despachan nuevas acciones
  // );


  logout$ = createEffect(() =>
  this.actions$.pipe(
    ofType(logout),
    tap(() => {
      this.googleService.singOut(); // Llama al método de cierre de sesión de Google
      this.router.navigate(['/']); // Navega a la página de aterrizaje
    })
   ),
   { dispatch: false }
  );


}


