import { Component, OnInit } from '@angular/core';
import { GoogleService } from '../services/google.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { InformationService } from '../../services/information.service';
import { CreateAccount, GoogleUser, RegisterByGoogle } from 'src/app/interfaces/auth.interface';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { loginSuccess } from '../state/auth.actions';
import { Store } from '@ngrx/store';
import { EncryptService } from 'src/app/services/encrypt.service';
import { ApplicationService } from 'src/app/services/application.service';
import { SweetAlertService } from '@services';
import { catchError, of } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {

  public view = 'register';
  public registerUserForm!: FormGroup;
  public typesOfDocument: any[] = [];
  public registerData!: CreateAccount | RegisterByGoogle;
  public min: any;
  public max: any;


  constructor(private googleService: GoogleService,
              private infoService: InformationService,
              private authService: AuthService,
              private router: Router,
              private store: Store,
              private encryptService: EncryptService,
              public appService: ApplicationService,
              private swalService: SweetAlertService
              ) {}

  ngOnInit(): void {
    this.googleService.getUser().subscribe((user: GoogleUser) => {
      // console.log('Servicio de registro por Google cargado', user);
      this.registerByGoogle(user)
    });
    this.min= this.appService.dateOperations('subtract', 'years', 100);
    this.max= this.appService.dateOperations('subtract', 'years', 18)

  }

  /**
   * Cambia la vista del componente.
   * @param {string} view - Vista a la que se desea cambiar.
  */
  viewChange(view: string) {

    if(view === 'register') this.view = view;
    else {
      this.initializedForm();
      this.typesOfDocument = this.infoService.getDocumentTypes();
      this.view = view;
    }
  }

  initializedForm() {

    this.registerUserForm = new FormGroup({
      nombres: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      apellidos: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]),
      fechaNacimiento: new FormControl('', [Validators.required]),
      tipoDocumento: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required, Validators.pattern(/^3\d{9,9}/)]),
      correo: new FormControl('', Validators.required),
      contrasena: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(20)]),
      terms: new FormControl(false)
    });

    let telefono = this.registerUserForm.get('telefono');
    telefono?.valueChanges.subscribe((number) => {

      if(telefono?.valid) this.infoService.verifyWhatsAppNumber(number).subscribe( (existe: boolean) => {
        this.registerData = {
          ... this.registerData,
          cellPhones: [{
            phone: number,
            llamadas: true,
            whatsapp: existe,
            prefix: '+57'
          }]
        }
      });
    });

  }

  /**
   * Registra un usuario a través de un formulario.
  */
  registerByForm() {

    //console.log(this.registerUserForm);
    if (this.registerUserForm.invalid) {

      this.swalService.showInfo('Alerta !', 'Por favor revisa el formulario antes de continuar.');

      this.registerUserForm.markAllAsTouched();
      return;
    }

    const { nombres, apellidos, fechaNacimiento, tipoDocumento: { document, type }, correo, contrasena, terms } = this.registerUserForm.value;

    if(!terms) return this.swalService.showInfo('Alerta !','Por favor acepta los terminos y condiciones antes de continuar.');
    this.registerData = {
      ... this.registerData,
      nombres,
      apellidos,
      correo,
      tipoCuenta: "Usuario",
      contrasena:  this.encryptService.manageActions({ action: 'password', data: contrasena }),
      numeroDocumento: document,
      tipoDocumento: type.toUpperCase(),
      fechaNacimiento,
      registerData: {
        method: 'form',
        platform: 'web',
        registerBy: 'yourself'
      }
    };

    this.register(this.registerData);
  }

  /**
   * Registra un usuario a través de Google.
   * @param {GoogleUser} user - Información del usuario de Google.
  */
  registerByGoogle(user: GoogleUser) {

    if(!user) return;

    const { email, firstName, lastName, photoUrl } = user;

    const registerData: RegisterByGoogle = {
      nombres: firstName,
      apellidos: lastName,
      correo: email,
      tipoCuenta: 'Usuario',
      google: true,
      avatar: photoUrl,
      registerData: {
        method: 'google',
        platform: 'web',
        registerBy: 'yourself'
      }
    };

    this.register(registerData);
  }

  /**
   * Realiza el proceso de registro.
   * @param {CreateAccount | RegisterByGoogle} user - Datos del usuario a registrar.
  */
  register(user: CreateAccount | RegisterByGoogle) {

    this.authService.register(user).pipe(
      catchError((error: any) => {
         
        // console.log('my error bitch', error);
        if(error.ok === false && error.status === 400) {
          this.swalService.showError('Error en el registro','Este usuario ya existe');
        }

        // console.error('Error en la solicitud:', error);
    return of(null);
      })).subscribe(
         
      (res: any) => {
         try {
          const {body} = res;
          this.googleService.resetToken();
          this.store.dispatch(loginSuccess({body}));
    
          // console.log('resp', body);
          
         
    
          // Verificar si hay datos en el localStorage
    
          const storedUsdet = localStorage.getItem('usdt');
          const storedToken = localStorage.getItem('tkn');
          const storedSession = localStorage.getItem('session');
    
          if (storedUsdet && storedToken && storedSession) {
            // Si hay datos redirigir
            this.swalService.showSuccess('Inicio de sesión', 'Bienvenido a Descuentos Médicos.');
    
            this.router.navigate(['/user/registro']);
          }
         } catch (error:any) {

          return
            // console.log('error en el catch', error);
            // if(error.ok === false && error.status === 400) {
            //   this.swalService.showError('Error en el registro','Este usuario ya existe');
            // }
         }

      
    },)
  }

}
